var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$can('view', 'AlarmeAcesso') && _vm.isReady)?_c('section',{staticClass:"alarm-panel"},[_c('div',{staticClass:"box",class:_vm.isEditing ? 'box-warning' : 'box-primary'},[(_vm.mode == 'editor')?[_c('DashboardPanelHeader',{ref:"panelHeader",attrs:{"panel":_vm.panel,"mode":_vm.mode,"isEditing":_vm.isEditing,"equipment":_vm.equipment,"customBoxHeaderClasses":"with-border"},on:{"title:click":function($event){return _vm.$emit('panelProperties')}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_vm._t("toolbar")]},proxy:true}],null,true)})]:_vm._e(),_c('div',{staticClass:"nav-tabs-custom"},[(_vm.mode != 'editor')?_c('ul',{staticClass:"nav nav-tabs pull-right ui-sortable-handle"},[(_vm.historyEnabled)?[(_vm.$can('view', 'HistoricoAlarmeAcesso'))?_c('li',{class:{active: _vm.tab == 0, clicable: _vm.tab != 0},attrs:{"title":_vm.$t('titles.view_history')}},[_c('a',{attrs:{"aria-expanded":"true"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.tab = 0}}},[_c('i',{staticClass:"fa fa-history"})])]):_vm._e(),(_vm.$can('view', 'AlarmeAcesso'))?_c('li',{class:{active: _vm.tab == 1, clicable: _vm.tab != 1},attrs:{"title":_vm.$t('titles.view_config')}},[_c('a',{attrs:{"aria-expanded":"true"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.tab = 1}}},[_c('i',{staticClass:"fa fa-cog"})])]):_vm._e()]:_vm._e(),_c('li',{staticClass:"pull-left header"},[(_vm.tab == 1)?[_c('DashboardPanelTitle',{attrs:{"panel":_vm.panel,"connector":_vm.equipment}})]:_vm._e(),(_vm.historyEnabled)?[(_vm.tab == 0)?[_c('DashboardPanelTitle',{attrs:{"panel":_vm.panel,"connector":_vm.equipment}}),_c('div',{staticClass:"tab-toolbar-control",staticStyle:{"margin":"0 5px"}},[(
                    _vm.$can('view', 'HistoricoAlarmeAcesso') &&
                      _vm.isReady &&
                      _vm.showDatetimePicker
                  )?_c('DatetimeRangePicker',{attrs:{"startRef":_vm.startRef,"endRef":_vm.endRef,"min":_vm.queryLimit * 24,"isHistory":true},on:{"interval-changed":_vm.intervalChanged,"dateRangePickerEvent":_vm.onDateRangePickerEvent}}):_vm._e()],1),(
                  true //toolbar.largelongIntervalSelection
                )?_c('div',{staticClass:"tab-toolbar-control"},[_c('IntervalDropDown',{model:{value:(_vm.queryLimit),callback:function ($$v) {_vm.queryLimit=$$v},expression:"queryLimit"}})],1):_vm._e(),(!_vm.downloadOnly)?_c('div',{staticClass:"tab-toolbar-control"},[(_vm.dropdownOptions.length)?_c('dropdown',{attrs:{"position":"right","data":_vm.dropdownOptions,"multiple":true,"title":`${_vm.$t('titles.filter_by')}: ${_vm.$tc('data', 1)}`},on:{"apply":_vm.dropdownApply}},[_c('span',[_c('i',{staticClass:"fa fa-filter toolbar-btn-icon"})])]):_vm._e()],1):_vm._e(),(
                  _vm.$can('view', 'HistoricoAlarmeAcesso') &&
                    _vm.isReady &&
                    !_vm.downloadOnly
                )?_c('div',{staticClass:"tab-toolbar-control"},[_c('DownloadButton',{attrs:{"tableId":"alarmHistoryReport","downloading":_vm.downloading},on:{"ready":function($event){_vm.downloading = true},"done":function($event){_vm.downloading = false}}})],1):_vm._e()]:_vm._e()]:_vm._e(),_vm._t("toolbar")],2)],2):_vm._e(),_c('div',{ref:"tabContent",staticClass:"tab-content panel-content"},[_c('div',{staticClass:"active tab-pane"},[_c('EquipmentAlarmSearchBar',{attrs:{"alarmList":_vm.alarmList,"panel":_vm.panel},on:{"filtered":_vm.onFilteredAlarmListId}}),(_vm.tab)?_c('EquipmentAlarmConfigurationDisplay',{attrs:{"mode":_vm.mode,"equipment":_vm.equipment,"alarmList":_vm.alarmList,"filteredAlarmIdList":_vm.selected,"display":_vm.display,"panelOptions":_vm.panelOptions,"busy":_vm.busy},on:{"refresh":_vm.fetchAlarmList,"widthError":_vm.onWidthError}}):(_vm.historyEnabled)?[(_vm.downloadOnly && _vm.showDatetimePicker)?_c('HistoryDownloadContainer',{attrs:{"startDate":_vm.startDate,"endDate":_vm.endDate,"items":_vm.historyEnabledFilteredAlarmList,"selected":_vm.selected,"idFieldName":"alarm_ids"}}):_c('EquipmentAlarmHistoryDisplay',{attrs:{"mode":_vm.mode,"equipment":_vm.equipment,"display":_vm.display,"panelOptions":_vm.panelOptions,"startDate":_vm.startDate,"endDate":_vm.endDate,"downloading":_vm.downloading,"downloadOnly":_vm.downloadOnly,"busy":_vm.busy,"alarmList":_vm.historyEnabledFilteredAlarmList}})]:_vm._e()],2)])])],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }