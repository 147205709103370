<template>
  <div class="download-container">
    <div v-if="task && isAValidTask">
      <div class="download-options">
        <p>
          <b>{{ $t("last_request") }}</b>
          :
          <span class="small">
            {{ $dt.format(task.start + "+00:00") }} -
            {{ $dt.format(task.end + "+00:00") }}
          </span>
        </p>
        <p>
          <b> {{ $t("request_status") }} </b>:
          <template v-if="'file_path' in task">
            <span class="text-success">
              <i class="fa fa-check mx-5"></i> {{ $t("done") }}
            </span>
            <span v-if="!task.file_path">
              <br /><br />
              <i class="fa fa-exclamation-triangle  mx-5"></i>
              {{ $t("no_data_found") }}
              <br /><br />
            </span>
          </template>
          <span class="text-danger" v-else-if="task.error">
            <i class="fa fa-exclamation-triangle mx-5"></i>
            {{ task.error }}
          </span>
          <span class="text-warning" v-else-if="task.task_id">
            <i class="fa fa-refresh fa-spin mx-5"></i>
            {{ $t("processing") }}
          </span>
          <span class="" v-else-if="!task.task_id">
            <i class="fa fa-refresh fa-spin  mx-5"></i>
            {{ $t("saving") }}
          </span>
        </p>
        <p
          v-if="'file_path' in task && task.is_limit_reached == true"
        >
          <b> {{ $t("history_warning") }} </b>:
          <span class="text-warning">
            <i class="fa fa-warning mx-5"></i> 
            <span v-if="task.data_ids">
              {{ $t("history_data_limit_reached") }}
            </span>
            <span v-if="task.alarm_ids">
              {{ $t("history_alarm_limit_reached") }}
            </span>
          </span>
        </p>
        <div class="text-center download-result-controls">
          <button
            v-if="'file_path' in task || task.error"
            class="btn btn-default"
            @click="onRequestReset"
          >
            {{ $t("back") }}
          </button>
          <button
            class="btn btn-success"
            v-if="task.file_path"
            @click="onFileDownload"
            :disabled="downloading"
          >
            <i
              class="fa fa-download"
              :class="downloading ? 'fa fa-refresh fa-spin' : 'fa fa-download'"
            ></i>
            {{ $t("download") }}
          </button>
          <button
            v-else-if="task.error"
            class="btn btn-primary"
            @click="onRequestAgain"
            style="margin-left:10px;"
          >
            {{ $t("retry") }}
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="download-options">
        <div class="header">
          <i class="fa fa-download"></i>
          {{
            $t("download_historical_records_of", { what: $tc(entityType, 1) })
          }}
        </div>
        <p>
          <b>{{ $tc("interval", 1) }}:</b>
          <span class="small" style="margin-left:10px;">
            {{ $t("from") }}
            <span class="text-bold">
              {{ $dt.format(startDate) }}
            </span>
            {{ $t("to") }}
            <span class="text-bold">
              {{ $dt.format(endDate) }}
            </span>
            <span class="text-danger" style="margin-left: 5px">
              ({{ days }} {{ $t("days").toLowerCase() }})
            </span>
          </span>
        </p>
        <div v-if="selectableList.length" class="no-select">
          <div>
            <div class="checkbox all">
              <label>
                <input type="checkbox" v-model="all" ref="all" />
                <span class="clicable">
                  {{ $tc("all", 1) }}
                </span>
              </label>
            </div>
          </div>
          <div class="form-group items">
            <div class="checkbox" v-for="item in selectableList" :key="item.id">
              <label>
                <input type="checkbox" v-model="idList" :value="item.id" />
                <span class="clicable">
                  {{ item.name }}
                </span>
              </label>
            </div>
          </div>
          <div>
            <span>
              {{
                $tc("n_item_selected", idList.length > 1 ? 2 : 1, {
                  n: idList.length
                })
              }}
            </span>
          </div>
        </div>
        <div v-else>{{ $t("there_are_no_items_configured") }}</div>
      </div>
      <template v-if="!isDatetimePickerVisible">
        <div class="text-center download-confirm" v-if="idList.length">
          <button
            class="btn btn-primary"
            @click.prevent.stop="onRequestDownload"
            :disabled="task"
          >
            <i class="fa fa-check"></i> {{ $t("confirm") }}
          </button>
        </div>
        <div class="text-center">
          <p v-if="task && !isAValidTask" class="text-danger">
            <i class="fa fa-exclamation-triangle"></i>
            {{ $t("there_is_already_an_active_download_task") }}
          </p>
          <p v-else-if="idList.length">
            {{ $t("dataset_will_be_available_for_download") }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import messages from "@/i18n/datetime";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "HistoryDownloadContainer",
  mixins: [MixinAlert],
  i18n: { messages },
  props: {
    startDate: { type: Object, required: true, default: () => null },
    endDate: { type: Object, required: true, default: () => null },
    items: { type: Array, required: false, default: () => [] },
    selected: { type: Array, required: false, default: () => [] },
    idFieldName: { type: String, required: false, default: "data_ids" },
    isDatetimePickerVisible: { type: Boolean, required: false, default: false }
  },
  data: () => {
    return { isReady: false, downloading: false, idList: [] };
  },
  computed: {
    contract() {
      return this.$store.getters["user/loggedUser"]?.contract || null;
    },
    taskEntityName() {
      if (this?.task?.data_ids) {
        return this.entityTypeName("data_ids");
      } else if (this?.task?.alarm_ids) {
        return this.entityTypeName("alarm_ids");
      }
      return "";
    },
    task() {
      let task = this.$store.getters["tasks/task"] || null;
      if (task) {
        if (task.timestamp) {
          let expiresAt = new Date(task.timestamp + 20 * 60 * 60000); // + 20h
          if (new Date().getTime() > expiresAt) {
            return null;
          }
        }
      }
      return task;
    },
    isAValidTask() {
      if (!this.task || !(this.idFieldName in this.task)) {
        return false;
      }
      if (this.task[this.idFieldName]) {
        let ids = (this.items || []).map(({ id }) => parseInt(id));
        let tids = (this.task[this.idFieldName] || "").split(",") || [];
        let found = tids.filter((id) => {
          return ids.indexOf(parseInt(id)) >= 0;
        });
        return found.length > 0;
      }
      return false;
    },
    selectableList() {
      return this.items
        .filter(({ history_enabled }) => history_enabled)
        .map((item) => ({
          id: item.id,
          name: this.$utils.proper(item.name)
        }));
    },
    selectableIds() {
      return this.selectableList.map(({ id }) => id);
    },
    all: {
      set(value) {
        this.idList = value ? this.selectableList.map(({ id }) => id) : [];
      },
      get() {
        return (
          this.idList.length > 0 &&
          this.idList.length == this.selectableList.length
        );
      }
    },
    entityType() {
      return this.entityTypeName(this.idFieldName);
    },
    days() {
      let days = this.endDate.diff(this.startDate, "days") + 1;
      return days > this.contract.history_long_limit_to_query
        ? this.contract.history_long_limit_to_query
        : days;
    }
  },
  watch: {
    idList: {
      handler() {
        if (this.$refs.all) {
          this.$refs.all.indeterminate =
            this.idList.length > 0 &&
            this.idList.length < this.selectableList.length;
        }
      }
    },
    selectableIds(n) {
      this.idList = this.idList.filter((id) => n.indexOf(id) >= 0);
    }
  },
  methods: {
    entityTypeName(i) {
      return {
        data_ids: "data",
        alarm_ids: "alarm"
      }[i];
    },
    operator(i) {
      return { data_ids: "data_between", alarm_ids: "alarms_between" }[i];
    },
    onRequestDownload() {
      if (this.task) return;
      if (!this.idList.length) {
        this.isReady = true;
        return;
      }
      let sysTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let usrTimezone = this?.$http?.options?.config?.dashboard?.timezone || "";
      let query = {
        timestamp: new Date().getTime(),
        contract_id: this.contract.id,
        start: moment(this.startDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(this.endDate)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss"),
        download_file: true,
        return_format: "csv",
        return_timezone: usrTimezone || sysTimezone
      };
      // data_ids or alarm_ids
      query[this.idFieldName] = this.idList.join(",");
      query["operator"] = this.operator(this.idFieldName);
      this.createTask(query);
    },
    onRequestAgain() {
      let query = {
        contract_id: this.task.contract_id,
        start: this.task.start,
        end: this.task.end,
        download_file: true,
        return_format: "csv"
      };
      let idFieldName = "";
      if (this.task.data_ids) {
        idFieldName = "data_ids";
      } else if (this.task.alarm_ids) {
        idFieldName = "alarm_ids";
      }
      if (!idFieldName) return;
      query[idFieldName] = this.task[idFieldName];
      query["operator"] = this.operator(idFieldName);
      this.createTask(query);
    },
    createTask(query) {
      this.$store.commit("tasks/SET_TASK", null); // reset previous
      this.$store.dispatch("tasks/createTask", query).then(
        (response) => {
          if (!this.validateSaveResponse(response)) {
            this.showAlert(() => {
              this.$store.commit("tasks/SET_TASK", null);
            });
          }
        },
        (error) => {
          this.validateSaveResponse(error);
          this.showAlert(() => {
            this.$store.commit("tasks/SET_TASK", null);
          });
        }
      );
    },
    onRequestReset() {
      this.$store.commit("tasks/SET_TASK", null); // reset previous
    },
    onFileDownload() {
      let task = JSON.parse(JSON.stringify(this.task));
      let dti = this.$dt.format(task.start + "+00:00");
      let dte = this.$dt.format(task.end + "+00:00");
      // let filename = `${dti}_${dte}(${task.data_ids}).csv`.replace(
      //   /[\-T\/\:]/g,
      //   ""
      // );
      let filename = `${dti}_${dte}.csv`.replace(/[\s\-T/:]/g, "");
      this.downloading = true;
      this.$http.get(task.file_path + "?ts=" + new Date().getTime()).then(
        (response) => {
          if (response?.bodyText) {
            var el = document.createElement("a");
            document.body.appendChild(el);
            el.href =
              "data:text/plain;charset=utf-8," +
              encodeURIComponent(response?.bodyText);
            el.download = filename;
            el.click();
            document.body.removeChild(el);
            this.downloading = false;
            this.$store.commit("tasks/SET_TASK", null); // reset previous
          } else {
            this.downloading = false;
            delete task.file_path;
            task.error = "Download Error";
            this.$store.commit("tasks/SET_TASK", task); // reset previous
          }
        },
        (error) => {
          // console.log(error);
          this.downloading = false;
          delete task.file_path;
          task.error = "Download Error";
          this.$store.commit("tasks/SET_TASK", task); // reset previous
        }
      );
    }
  },
  mounted() {
    this.$store.dispatch("tasks/checkTask");
  },
  created() {
    let ids = this.selected.filter((id) => {
      return this.selectableList.find((item) => id == item.id);
    });
    if (ids.length == this.selectableList.length) {
      this.all = true;
    } else {
      this.idList = ids;
    }
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.clicable {
  cursor: pointer;
  opacity: 0.8;
}

.skin-dark .clicable {
  opacity: 1;
  color: var(--skin-dark-light);
}

.skin-dark .clicable:hover,
.skin-dark .clicable:focus {
  color: #fff;
}

.download-options {
  padding: 20px 10px;
  margin: 10px 0 20px 0;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.header {
  padding: 0 0 10px 0;
  font-size: 14pt;
  color: #666;
}
.skin-dark .header {
  color: var(--skin-dark-light);
}
.download-confirm p {
  margin-top: 10px;
}

.download-result-controls button {
  margin: 10px;
}

.all {
  border-bottom: 1px solid lightgray;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.items {
  max-height: 200px;
  overflow: auto;
}
.mx-5 {
  margin: 0 5px;
}
</style>
