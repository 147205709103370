<template>
  <div>
    <div class="row">
      <div class="form-group col-md-6" v-if="locked">
        <div class="input-group">
          <div class="input-group-addon">{{ $tc(myLabel) }}</div>
          <input
            type="text"
            class="form-control"
            v-bind:value="value.name"
            disabled
          />
          <div
            class="input-group-addon btn"
            data-toggle="popover"
            :data-content="$t('titles.parent_selection_cant_unlock')"
            :disabled="!canUnlock"
            v-on:click.stop.prevent="canUnlock ? (locked = !locked) : locked"
          >
            <i class="fa fa-lock"></i>
          </div>
        </div>
      </div>
      <template v-else>
        <div
          class="form-group "
          v-bind:class="{
            'col-md-4 col-sm-6': controls.length > 2,
            'col-md-6': controls.length <= 2
          }"
          v-for="(item, ix) in controls"
          v-bind:key="ix"
        >
          <FormParentSelection
            v-if="item.ready"
            v-model="item.entry"
            :locker="false"
            :label="item.label"
            :service="item.service"
            :value="item.entry"
            :filter="getFilter(ix)"
            :filterFunction="item.filter_function"
            :disabled="item.disabled"
            v-on:changed="changed(ix)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FormParentSelection from "@/components/registration/form-parent-selection.vue";
function defaultControl() {
  return {
    entry: {id: 0},
    filter_by: "",
    label: "",
    service: null,
    ready: false,
    locked: true,
    disabled: false
  };
}
export default {
  name: "FormParentsSelection",
  components: {
    FormParentSelection
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: Object,
      required: false,
      default: function() {
        return {id: 0, name: ""}; // the input/output object (model)
      }
    },
    filters: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    locker: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      controls: [],
      locked: true
    };
  },
  computed: {
    myLabel() {
      return (
        this.label ||
        (this.controls &&
          this.controls.length &&
          this.controls[this.controls.length - 1].label) ||
        ""
      );
    },
    canUnlock() {
      if (!this.locker) return true;
      return (this.controls || []).some(({disabled}) => !disabled);
    }
  },
  methods: {
    changed(ix) {
      let self = this;
      self.current_position = ix;
      for (var i = self.current_position + 1; i < self.controls.length; i++) {
        self.controls[i].ready = false;
      }
      self.$nextTick(function() {
        for (var i = self.current_position + 1; i < self.controls.length; i++) {
          self.controls[i].ready = true;
        }
      });
      if (ix == self.controls.length - 1) {
        var entry = self.controls[self.controls.length - 1].entry;
        self.$emit("input", {
          id: entry.id,
          name: entry.name
        });
      }
    },
    getFilter(ix) {
      var self = this;
      var filter = null;
      if (ix > 0) {
        for (var i = 0; i < ix; i++) {
          var control = self.controls[i];
          filter = filter || {};
          filter[control.filter_by] = control.entry.id;
        }
      }
      return filter;
    },
    setup() {
      var self = this;
      var controls = [];
      for (var i in self.filters) {
        var filter = self.filters[i];
        var control = defaultControl();
        control.entry.id = filter.entry.id;
        control.entry.name = filter.entry.name;
        control.filter_by = filter.filter_by;
        control.filter_function = filter.filter_function;
        control.label = filter.label;
        control.service = filter.service;
        control.disabled = filter.disabled ? true : false;
        control.ready = i == 0;
        controls.push(control);
      }
      self.$set(self, "controls", controls);
      self.locked = self.locker;
    }
  },
  created() {
    this.setup();
  },
  mounted() {
    $(this.$el)
      .find("[data-toggle=popover]")
      .popover({
        delay: {show: 300},
        placement: "auto right",
        trigger: "hover"
      });
  }
};
</script>

<style scoped></style>
