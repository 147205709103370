<template>
  <span
    v-if="modal"
    class="tooltip-container"
    :title="
      `${$tc('data', 1)}: ${entry && entry.name}\n${$t(
        'has_pending_commands'
      )}\n${$t('click_to_edit')}`
    "
  >
    <i
      class="fa fa-refresh fa-spin clicable"
      @click="displayTooltip = !displayTooltip"
      v-show="!displayTooltip"
    />
    <portal to="modal">
      <Modal
        :open.sync="displayTooltip"
        :title="entry && entry.name"
        :allowOverflow="true"
        :footer="false"
        :canClose="true"
        :centerTitle="false"
      >
        <template #content>
          <PendingCommandList
            :entry="entry"
            :pendingCommands="pendingCommands"
            :alerting="alerting"
            :mouseLeave="false"
            @close="displayTooltip = false"
            @removeFirstItem="
              displayTooltip = false;
              removeFirstItem();
            "
          >
          </PendingCommandList>
        </template>
      </Modal>
    </portal>
  </span>
  <span
    class="tooltip-container"
    @click.stop
    @mouseleave="!draggable && onMouseInOut(false)"
    v-else
  >
    <div>
      <i
        class="fa fa-refresh fa-spin clicable"
        @mouseover="onMouseInOut(true)"
        v-show="!displayTooltip"
      />
      <PendingCommandList
        v-if="displayTooltip"
        :title="entry && entry.name"
        :pendingCommands="pendingCommands"
        :alerting="alerting"
        :draggable="draggable"
        @close="displayTooltip = false"
        @removeFirstItem="removeFirstItem"
      >
      </PendingCommandList>
    </div>
  </span>
</template>

<script>
import PendingCommandList from "@/components/data/pending-command-list.vue";
import Modal from "@/components/widgets/modal.vue";
export default {
  name: "PendingCommandIndicator",
  components: {
    PendingCommandList,
    Modal
  },
  props: {
    entry: {
      type: Object,
      required: true
    },
    editor: {
      type: Boolean,
      default: false,
      required: false
    },
    modal: {
      type: Boolean,
      default: false,
      required: false
    },
    draggable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      tooltipRequested: false,
      displayTooltip: false,
      dissmissable: false,
      alerting: false
    };
  },
  computed: {
    pendingCommands() {
      let lst = JSON.parse(JSON.stringify(this?.entry?.pending_commands || []));
      // add mapping command to the view
      if (this?.entry?.pending_mapping_write) {
        lst.push(
          JSON.parse(JSON.stringify(this?.entry?.pending_mapping_write))
        );
      }
      lst.sort((a, b) => {
        if (a.created_datetime > b.created_datetime) return -1;
        if (b.created_datetime > a.created_datetime) return 1;
        return 0;
      });
      return lst;
    },
    firstPendingCommand() {
      return this.pendingCommands.find((i) => i.command_state_id == 1);
    }
  },
  methods: {
    remove() {
      let command = this.firstPendingCommand;
      let payload = {
        id: command.id,
        cancel_command: true
      };
      this.$store
        .dispatch("command/save", [payload])
        .then((result) => {
          this.alerting = false;
          if (result && result.length) {
            if (typeof result[0] == "string") {
              this.$swal({
                title: this.$t("item_could_not_be_deleted"),
                text: result[0],
                icon: "error"
              });
            } else {
              // begin - ToDo: move it to the command module
              let lst = this.$store.getters["dashboard/dataList"] || [];
              let data = lst.find((i) => i.id == this.entry.id);
              if (data) {
                let entry = {
                  data_id: data.id,
                  pending_commands: (data.pending_commands || []).filter(
                    (i) => i.id != command.id
                  )
                };
                this.$store.dispatch("dashboard/setDataValue", entry);
              }
              this.$emit("commandRemoved", command.id);
              // end
              let msg = this.$t("you_have_deleted_n_items", {count: 1});
              this.$toasted.show(msg, {
                position: "bottom-right",
                duration: 5000,
                iconPack: "fontawesome",
                icon: "fa-check",
                type: "success",
                action: {
                  icon: "fa-close",
                  onClick: (e, me) => {
                    me.goAway(0);
                  }
                }
              });
            }
          }
        })
        .catch((e) => {
          this.alerting = false;
        });
    },
    removeFirstItem() {
      let command = null;
      if (this.$can("manage", "DadoEscrita")) {
        command = this.firstPendingCommand;
      }
      if (command) {
        let self = this;
        let message = this.$t("removal_pending_command");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = "";
        field_name += `${this.$t("value")}: ${command.writing_value}`;
        if (field_name.length > 40) {
          field_name = field_name.substring(0, 36) + "...";
        }
        field_name += ` <br> ${this.$t("created_at")}: ${this.$dt.format(
          command.created_datetime
        )}`;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p><div class="text-warning"><i class="${cls}"></i> ${text}</div><br/></p>`;
        let html = "";
        html += `${message}<br/><b>${field_name}</b>${warning}`;
        let wrapper = document.createElement("div");
        wrapper.innerHTML = html;
        this.alerting = true;
        self
          .$swal({
            title: self.$t("are_you_sure"),
            content: wrapper,
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then((confirm) => {
            if (confirm) {
              self.remove();
            } else {
              this.alerting = false;
            }
          });
        return;
      } else if (this.editor) {
        this.alerting = false;
        this.$emit("click");
      }
    },
    onMouseInOut(option) {
      this.$emit("mouseInOut", option);
      this.tooltipRequested = option;
      if (option && !this._timer) {
        // this.displayTooltip=true;
        this._timer = setTimeout(() => {
          clearTimeout(this._timer);
          this._timer = null;
          this.displayTooltip = this.tooltipRequested;
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
.tooltip-container {
  position: relative;
  z-index: 2;
}

.tooltip-container > div {
  position: absolute;
  top: 0;
  left: 0;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
