<template>
  <div>
    <template>
      <div
        class="form-group"
        :class="{'has-error': notFound}"
        style="margin-bottom: 0px;"
      >
        <label v-if="!parentProcessAreaId" for="processAreaIdSelector"
          >{{ $tc("process_area") }}
          <ToolTip :title="hint || $parent.$t('hints.process_area')" />
        </label>
        <label
          class="no-select"
          style="height: 20px;"
          v-if="parentProcessAreaId"
        >
          <input
            type="checkbox"
            id="inherits_parent_process_area"
            data-testid="inherits-process-area"
            v-model="inherits_parent_process_area"
            :disabled="process_area_editing || disabled ? true : false"
          />
          {{ $t(`has_the_same_process_area_as_its.${label}`) }}
          <ToolTip :title="$t(`hints.inherits_parent_process_area.${label}`)" />
        </label>

        <div class="input-group" v-if="!disabled">
          <div
            class="input-group-addon hidden-xs"
            v-if="parentProcessAreaId && !process_area_editing"
          >
            {{ $tc("process_area") }}
          </div>
          <div
            class="form-control"
            v-if="!inline && editable && lastAreaName && !process_area_editing"
          >
            <i class="fa fa-refresh fa-spin"></i>
          </div>
          <v-select
            v-if="!process_area_editing"
            id="processAreaIdSelector"
            label="name"
            ref="inp"
            v-model="process_area_id"
            :disabled="
              inherits_parent_process_area ||
              disabled ||
              !editable ||
              process_area_editing
                ? true
                : false
            "
            :clearable="true"
            :options="processAreas"
            :placeholder="$tc('process_area', 1)"
            :reduce="(item) => item.id"
            @search:focus="$emit('focus')"
            class="style-chooser"
          >
            <span slot="no-options">{{ $t("no_result_found") }}</span>
          </v-select>
          <span
            v-if="
              $can('manage', 'AreaDeProcessoCadastro') &&
                !process_area_editing &&
                !disabled &&
                !inherits_parent_process_area &&
                process_area_id != 0
            "
            class="input-group-addon btn btn-default"
            :title="$t('edit')"
            @click.prevent.stop="edit()"
          >
            <i class="fa fa-pencil"></i>
          </span>
          <span
            v-if="
              $can('manage', 'AreaDeProcessoCadastro') &&
                !process_area_editing &&
                !disabled &&
                !inherits_parent_process_area &&
                process_area_id != 0
            "
            class="input-group-addon btn btn-primary"
            :title="$t('new_process_area')"
            @click.prevent.stop="add()"
          >
            <i class="fa fa-plus"></i>
          </span>
        </div>
        <v-select
          v-else
          id="processAreaIdSelector"
          label="name"
          ref="inp"
          v-model="process_area_id"
          :disabled="true"
          :clearable="true"
          :options="items"
          :placeholder="$tc('process_area', 1)"
          :reduce="(item) => item.id"
          @search:focus="$emit('focus')"
          class="style-chooser"
        >
          <span slot="no-options">{{ $t("no_result_found") }}</span>
        </v-select>
      </div>
    </template>

    <ProcessAreaInlineForm
      v-if="process_area_editing"
      :processArea="process_area_editing"
      @hide="onProcessAreaFormClosed"
    ></ProcessAreaInlineForm>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import ProcessAreaInlineForm from "@/components/process-area-inline-form.vue";
export default {
  name: "ProcessAreaSelection",
  components: {
    ToolTip,
    ProcessAreaInlineForm
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },
    parentProcessAreaId: {
      type: Number,
      required: false,
      default: 0 // 0 will disable the checkbox option
    },
    label: {
      type: String,
      required: false,
      default: "connector"
    },
    hint: {
      type: String,
      required: false,
      default: ""
    },
    allowNull: {
      type: Boolean,
      required: false,
      default: false
    },
    nullLabel: {
      type: String,
      required: false,
      default: function() {
        return this.$tc("none", 2);
      }
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      inherits_parent_process_area: false,
      process_area_id: 0,
      process_area_editing: null,
      lastAreaName: ""
    };
  },
  watch: {
    inherits_parent_process_area(n) {
      if (n) {
        this.$set(this, "process_area_id", this.parentProcessAreaId);
      }
      this.triggerValueChangeEvent();
    },
    process_area_id() {
      this.triggerValueChangeEvent();
    },
    processAreas() {
      this.selectLastProcessArea();
    },
    value(n) {
      if (!n || !n?.id) this.process_area_id = null;
    }
  },
  computed: {
    processAreas() {
      let aditional = [];
      if (
        this.allowNull &&
        this.$store.getters["processArea/processAreas"].length
      )
        aditional.push({id: 0, name: this.nullLabel});
      return aditional.concat(this.$store.getters["processArea/processAreas"]);
    },
    processArea() {
      let id = this.process_area_id;
      let lst = this.processAreas.filter(function(i) {
        return i.id == id;
      });
      return (lst.length && lst[0]) || null;
    },
    notFound() {
      if (!this.process_area_id) return false;
      let selected = (this.processAreas || []).find(
        ({id}) => parseInt(id) == parseInt(this.process_area_id)
      );
      return Boolean(!selected);
    }
  },
  methods: {
    triggerValueChangeEvent() {
      this.$emit("input", {
        ...this.processArea,
        inherits_parent_process_area: this.inherits_parent_process_area,
        id: this.process_area_id || null
      });
    },
    fetchProcessAreas() {
      this.$store.dispatch("processArea/fetchProcessAreas");
    },
    add() {
      if (this.inherits_parent_process_area || this.disabled) return;
      this.process_area_editing = {};
    },
    edit() {
      if (
        this.inherits_parent_process_area ||
        this.disabled ||
        this.process_area_id == 0
      )
        return;
      this.process_area_editing = JSON.parse(JSON.stringify(this.processArea));
    },
    onProcessAreaFormClosed(name) {
      this.process_area_editing = null;
      if (!name) {
        let pa = (this.processAreas || []).find(({is_default}) => is_default);
        this.lastAreaName = pa && pa.name;
        if (this.process_area_id) {
          this.$emit("processAreaRemoved", this.process_area_id);
        }
      } else {
        this.lastAreaName = name;
      }
      //===============
      // reset previous selection
      var query = this.$store.getters["equipment/query"] || {};
      query.query_process_area = query.query_process_area || {};
      query.query_process_area.id = "";
      this.$store.dispatch("equipment/setQuery", query);
      //===============
      this.selectLastProcessArea();
    },
    selectLastProcessArea() {
      if (this.lastAreaName) {
        let pa = (this.processAreas || []).find(
          (i) => i.name == this.lastAreaName
        );
        if (pa) {
          this.process_area_id = pa.id;
        }
      }
      this.lastAreaName = "";
    }
  },
  mounted() {
    this.inherits_parent_process_area =
      this.value?.inherits_parent_process_area ?? false;
    this.process_area_id =
      (this.inherits_parent_process_area && this.parentProcessAreaId) ||
      this.value?.id ||
      null;
    if (!(this.processAreas || []).length) {
      this.fetchProcessAreas();
    }
  }
};
</script>

<style scoped>
.input-group {
  width: 100%;
  max-width: inherit;
}

.input-group .input-group-addon.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
</style>
<style>
.has-error .vs__dropdown-toggle {
  color: #dd4b39 !important;
  border-color: #dd4b39 !important;
}

.has-error .vs__clear,
.has-error .vs__open-indicator {
  fill: #dd4b39 !important;
}

.has-error .fa {
  color: #dd4b39 !important;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  padding: 0;
  border-radius: 0px; /* AdminLTE does not make use of rounded border */
}

.style-chooser .vs__dropdown-toggle {
  height: 34px;
}

.style-chooser .vs__search {
  padding: 4px 10px;
  line-height: 1.4;
}

.style-chooser .vs__selected {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: -60px;
  overflow: hidden;
  width: 100%;
}

.vs--disabled .vs__selected {
  margin-right: 0;
}

.vs--disabled .vs__search {
  display: none;
}

:root {
  --vs-disabled-bg: #eee;
}
</style>
