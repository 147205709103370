<template>
  <span
    v-on:click="onClick"
    v-bind:style="computedStyle"
    class="toggle noselect"
    v-bind:class="disabled ? 'disabled' : ''"
  >
    <i class="fa" v-bind:class="cls"></i>
    <span v-if="label">{{ label }}</span>
  </span>
</template>

<script>
/*
use
<FormInputToggle labels="on,off" value="off"></FormInputToggle>
*/
export default {
  name: "FormInputToggle",
  data: function() {
    return {
      isOn: false
    };
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: function() {
        return "0";
      }
    },
    labels: {
      type: String,
      required: false,
      default: function() {
        return "";
      }
    },
    colors: {
      type: String,
      required: false,
      default: function() {
        return "#34c13a,#ff5722";
      }
    },
    labelOn: {
      type: String,
      required: false,
      default: function() {
        return "";
      }
    },
    labelOff: {
      type: String,
      required: false,
      default: function() {
        return "";
      }
    },
    colorOn: {
      type: String,
      required: false,
      default: function() {
        return "#34c13a";
      }
    },
    colorOff: {
      type: String,
      required: false,
      default: function() {
        return "#ff5722";
      }
    },
    fontSize: {
      type: String,
      required: false,
      default: "24"
    },
    border: {
      type: String,
      required: false,
      default: ""
    },
    padding: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    cls: function() {
      return this.isOn ? "fa-toggle-on" : "fa-toggle-off";
    },
    label: function() {
      var ret = "";
      if (this.isOn) {
        ret =
          (this.labels && (this.labels + ",").split(",")[0]) || this.labelOn;
      } else {
        ret =
          (this.labels && (this.labels + ",").split(",")[1]) || this.labelOff;
      }
      return ret;
    },
    color: function() {
      var ret = "#000";
      if (this.isOn) {
        ret = this.colors.split(",")[0];
      } else {
        ret = this.colors.split(",")[1];
      }
      ////console.log(ret);
      return ret;
    },
    computedStyle() {
      if (this.$root.isDarkTheme) {
        return {
          'font-size': '18pt',
          'color': 'var(--skin-dark-light)',
          'border': '1px solid var(--skin-dark-darker)',
          'padding': '2px 8px',
          'white-space': 'nowrap',
          'background-color': 'var(--skin-dark-dark)'
        }  
      }
      return {
        'fontSize': this.fontSize + 'pt',
        'color': this.color,
        'border': this.border || '1px solid #dfdfdf',
        'padding': this.padding || '2px 8px',
        'whiteSpace': 'nowrap'
      }
    }
  },
  watch: {
    value: {
      handler: "toggle",
      immediate: true
    }
  },
  methods: {
    onClick: function() {
      if (!this.disabled) {
        this.$emit("input", this.isOn ? "0" : "1");
      }
    },
    toggle: function() {
      if (isNaN(Number(this.value))) {
        this.isOn =
          this.value.toUpperCase() == "ON" || this.value.toUpperCase() == "TRUE"
            ? true
            : false;
      } else {
        this.isOn = Number(this.value) ? true : false;
      }
    }
  }
};
</script>

<style scoped>
.toggle {
  text-transform: uppercase;
  border-radius: 10px;
  opacity: 0.8;
}
.toggle:hover {
  cursor: pointer;
  background-color: #efefef;
  opacity: 1;
}
.skin-dark .toggle,
.skin-dark .toggle:hover {
  opacity: 1;
}
.toggle > i {
  margin: 0 5px;
}
.disabled:hover {
  cursor: not-allowed;
  opacity: 0.8;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>
