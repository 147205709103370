<template>
  <div>
    <div class="form-inline">
      <div class="form-group" :style="`width: calc(100% - ${toolbarWidth}px);`">
        <input
          type="text"
          class="form-control"
          data-testid="name"
          v-model="area_name"
          v-bind:placeHolder="$t('process_area_name')"
          ref="area_name"
          v-bind:disabled="!isReady"
          style="width:100%"
          @keydown.enter.exact.stop="save"
        />
        <div class="inner-label">{{ $t("name") }}</div>
      </div>
      <div class="form-group text-right" :style="`width:${toolbarWidth}px`">
        <button
          class="btn btn-default btn-gap"
          data-testid="hide"
          v-on:click="hide(false)"
          v-bind:disabled="!isReady"
        >
          <i class="fa fa-undo"></i>
        </button>
        <button
          class="btn btn-default btn-gap"
          data-testid="remove"
          v-on:click.prevent.stop="remove"
          v-bind:disabled="!isReady || processArea.is_default"
          v-if="!isNew && $can('manage', 'AreaDeProcessoEscrita')"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          class="btn btn-primary"
          data-testid="save"
          v-on:click.prevent.stop="save"
          v-bind:disabled="!isReady"
          v-if="$can('manage', 'AreaDeProcessoEscrita')"
        >
          <i v-if="isReady" class="fa fa-check"></i>
          <i v-else class="fa fa-refresh fa-spin"></i>
        </button>
      </div>
    </div>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        data-testid="description"
        v-model="area_description"
        v-bind:placeHolder="$t('process_area_description')"
        v-bind:disabled="!isReady"
      />
      <div class="inner-label">{{ $t("description") }}</div>
    </div>
  </div>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "ProcessAreaInlineForm",
  mixins: [MixinAlert],
  data() {
    return {
      action: "",
      area_name: "",
      area_description: "",
      etag: ""
    };
  },
  props: {
    processArea: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    isReady() {
      return this.$store.getters["processArea/isReady"];
    },
    isNew() {
      return !this?.processArea?.id;
    },
    toolbarWidth() {
      return this.isNew ? 130 - 40 : 130;
    }
  },
  methods: {
    hide(isDirty) {
      this.$emit("hide", isDirty ? this.area_name : "");
    },
    remove() {
      let self = this;
      if (self.processArea && "id" in self.processArea) {
        self
          .$swal({
            title: self.$t("are_you_sure"),
            text:
              self.$t("this_operation_might_be_affecting_other_equipment") +
              "\n" +
              self.$t("you_wont_be_able_to_revert_this"),
            icon: "warning",
            buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
          })
          .then(function(isConfirm) {
            if (isConfirm) {
              self.action = "remove";
              self.$store.dispatch("processArea/remove", self.processArea);
              self.area_name = "";
              self.area_description = "";
              self.etag = "";
              self.hide(true); // hide with changes
            }
          });
      }
    },
    save() {
      if (!this.area_name) return;
      let entry = {
        name: this.area_name,
        description: this.area_description || "",
        contract_id: this.$store.getters["user/loggedUser"].contract_id
      };
      if (this.processArea && "id" in this.processArea) {
        entry.id = this.processArea.id || "";
        entry.etag = this.processArea.etag || "";
        this.etag = this.processArea.etag;
      }
      this.action = "save";
      this.$store
        .dispatch("processArea/save", entry)
        .then((data) => {
          if (this.validateSaveResponse(data)) {
            this.action = "";
            this.area_name = data.name;
            this.area_description = data.description;
            this.etag = data.etag;
            this.hide(true); // hide with changes
          } else {
            this.showAlert();
          }
        })
        .catch((e) => {
          this.action = "";
          if (!this.validateSaveResponse(e)) {
            this.showAlert(() => {
              this.focus();
            });
          }
        });
    },
    focus() {
      if (this.$refs.area_name) this.$refs.area_name.focus();
    }
  },
  mounted() {
    this.focus();
    if (this.processArea) {
      this.area_name = this.processArea.name;
      this.area_description = this.processArea.description;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-gap {
  margin-right: 5px;
}

.form-group {
  position: relative;
}

.form-group > input {
  padding: 10px 12px 0px 12px;
}

.inner-label {
  position: absolute;
  top: 1px;
  left: 3px;
  font-size: 65%;
  color: #666;
}

.skin-dark .inner-label {
  color: var(--skin-dark-light);
}

.form-inline .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
</style>
