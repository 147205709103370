<template>
  <section>
    <div class="input-group">
      <div class="input-group-addon">{{ $tc(label) }}</div>
      <div class="form-control" v-if="busy">
        <i class="fa fa-refresh fa-spin"></i>
      </div>
      <template v-else>
        <input
          class="form-control"
          v-if="locked"
          v-bind:value="name || ''"
          disabled
        />
        <template v-else>
          <v-select
            class="style-chooser"
            v-if="!locked && filteredItems && filteredItems.length"
            @input="id = $event"
            label="name"
            :clearable="false"
            :value="id"
            :options="filteredItems"
            :reduce="({id}) => id"
            :disabled="$attrs.disabled || filteredItems.length == 1"
            :data-testid="'parent-' + label"
          >
            <span slot="no-options">{{ $t("no_result_found") }}</span>
          </v-select>
          <input class="form-control" v-else value="" disabled />
        </template>
      </template>
      <div
        class="input-group-addon btn"
        v-if="locker"
        :data-content="
          $t(
            locked
              ? 'titles.parent_selection_locked'
              : 'titles.parent_selection_unlocked'
          )
        "
        data-toggle="popover"
        v-on:click.stop.prevent="onLockClicked"
      >
        <i class="fa fa-lock" v-if="locked"></i>
        <i class="fa fa-unlock" v-else></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FormParentSelection",
  props: {
    service: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: "Type"
    },
    value: {
      type: Object,
      required: false,
      default: function() {
        return {id: 0, name: "untitled"};
      }
    },
    filter: {
      type: Object,
      required: false,
      default: function() {
        return null;
      }
    },
    filterFunction: {
      type: Function,
      required: false,
      default: null
    },
    locker: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      items: null,
      id: 0,
      name: "",
      locked: true,
      busy: false
    };
  },
  computed: {
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id;
    },
    selected() {
      let lst = [];
      let id = this.id || 0;
      if (id) {
        lst = (this.items || []).filter(function(i) {
          return i.id == id;
        });
      }
      return (lst && lst.length && lst[0]) || null;
    },
    filteredItems() {
      return Object.keys(this.filter || {}).length == 2
        ? (this.items || []).filter(
            ({is_local, memory_size}) => parseInt(memory_size) == 1 && !is_local
          )
        : this.items;
    }
  },
  watch: {
    busy(n, o) {
      this.$emit("busy", n);
    },
    id: {
      handler(n, o) {
        let entry = this.selected;
        if (n && entry) {
          this.$emit("input", {id: entry.id, name: entry.name});
          this.$emit("changed");
        }
      },
      deep: true
    }
  },
  methods: {
    onLockClicked() {
      if (this.busy) {
        return;
      }
      this.locked = !this.locked;
      if (!this.locked) {
        this.fetch();
      }
    },
    fetch() {
      let self = this;
      self.busy = true;
      let query = null;
      if (self.filter) {
        query = self.filter;
      }
      query = query || {};
      query.contract_id = this.contract_id;
      self.service.fetch(query).then((response) => {
        self.busy = false;
        if (response && response.length) {
          if ("filter" in self.service) {
            response = self.service.filter(response);
          }

          if (self?.filterFunction) {
            response = response.filter(self.filterFunction);
          }
          self.$set(self, "items", response);
          if (response.length) {
            if (!self.selected) {
              this.id = response[0].id;
              this.name = response[0].name;
            } else {
              this.$emit("changed");
            }
          } else {
            // this.$emit("input", { id: 0, name: "" });
            // this.$emit("changed");
            // this.$emit("busy", false);
          }
        }
      });
    }
  },
  created() {
    if (this.value) {
      this.id = this.value.id || 0;
      this.name = this.value.name || "";
    }
    if (!this.locker) {
      this.$nextTick(function() {
        this.onLockClicked();
      });
    }
  },
  mounted() {
    $(this.$el)
      .find("[data-toggle=popover]")
      .popover({
        delay: {show: 300},
        placement: "auto right",
        trigger: "hover"
      });
  }
};
</script>

<style>
/* .vs__selected {
  white-space: nowrap;
  max-width: 210px;
  overflow: hidden;
} */
/* .style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
} */

.vs__search:disabled {
  display: none;
}

.style-chooser .vs__selected {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 500px) {
  .style-chooser .vs__selected {
    max-width: 200px;
  }
}
</style>
