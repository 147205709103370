<template>
  <div
    class="box tooltip-overflow"
    :class="title ? 'box-default box-solid' : ''"
    ref="details"
    v-if="pendingCommands.length"
  >
    <div
      class="custom-box-header with-border"
      :class="{draggable: draggable}"
      v-if="title"
    >
      <div class="text-bold">{{ title }}</div>
      <div class="btn btn-xs" data-widget="remove" @click.stop="$emit('close')">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <div class="box-body">
      <div style="margin: -5px 0 5px 0;opacity: .7">
        {{ $t("has_pending_commands") }}
      </div>
      <div>
        <table
          class="table table-bordered table-condensed"
          style="background-color: inherit;opacity: 1;white-space:nowrap; margin-bottom: 0"
        >
          <tbody>
            <tr>
              <td style="max-width:150px;">{{ $t("value") }}</td>
              <td>{{ this.$t("datetime") }}</td>
              <td>{{ this.$tc("sent", 1) }}</td>
            </tr>
            <template v-for="(i, ix) in pendingCommands || []">
              <tr :key="i.id" :class="{'bg-red': ix == 0}">
                <td style="max-width:150px;overflow:hidden;">
                  {{ i.writing_value }}
                </td>
                <td>{{ $dt.format(i.created_datetime) }}</td>
                <td :title="i.processed_datetime">
                  <i class="fa fa-check" v-if="i.processed_datetime"></i>
                  <span
                    v-else
                    @mouseenter="ix == 0 ? (dissmissable = true) : 0"
                    @mouseleave="dissmissable = false"
                    @click.stop.prevent="
                      dissmissable && $emit('removeFirstItem')
                    "
                  >
                    <i
                      v-if="i.command_state_id"
                      :class="
                        (dissmissable || alerting) && ix == 0
                          ? 'item-status-icon fa fa-trash clicable'
                          : 'item-status-icon fa fa-refresh fa-spin'
                      "
                    ></i>
                    <span v-else>
                      <i class="fa fa-share-alt"> </i>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <i class="item-status-icon fa fa-refresh fa-spin"></i>
                    </span>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PendingCommandList",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    pendingCommands: {
      type: Array,
      required: true,
      default: () => []
    },
    alerting: {
      type: Boolean,
      required: false,
      default: false
    },
    mouseLeave: {
      type: Boolean,
      required: false,
      default: true
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dissmissable: false
    };
  },
  methods: {
    hideDetails() {
      const self = this;
      setTimeout(() => {
        $(this.$refs.details).fadeOut(400, () => {
          setTimeout(() => {
            self.$emit("close");
          }, 100);
        });
      }, 300);
    }
  },
  mounted() {
    // evaluate screen and panel boundaries (maz)
    const hiddenBy = (el, rect) => {
      let parent = el.parentElement;
      while (parent) {
        let parentRect = parent.getBoundingClientRect();
        let overflowStyle = getComputedStyle(parent).overflow;
        if (
          overflowStyle === "hidden" ||
          overflowStyle === "scroll" ||
          overflowStyle === "auto"
        ) {
          if (
            rect.left < parentRect.left ||
            rect.right > parentRect.right ||
            rect.top < parentRect.top ||
            rect.bottom > parentRect.bottom
          ) {
            return parentRect; // it returns the rect that clamp it
          }
        }
        parent = parent.parentElement;
      }
      return null;
    };
    let r = this.$el.getBoundingClientRect();
    if (r.x + r.width > window.innerWidth) {
      this.$el.style.marginLeft = `-${r.width - (window.innerWidth - r.x)}px`;
    } else {
      let parentBoundaries = hiddenBy(this.$el, r);
      if (parentBoundaries) {
        // console.log(hiddenBy);
        // console.log(r);
        if (r.x + r.width > parentBoundaries.width) {
          this.$el.style.marginLeft = `-${r.width -
            (parentBoundaries.width - r.x)}px`;
        }
        if (r.y + r.height > parentBoundaries.height) {
          this.$el.style.marginTop = `-${r.height -
            (parentBoundaries.height - r.y)}px`;
        }
      }
    }
    // this.$utils.moveToVisibleArea(this.$el);
    if (this.draggable) $(this.$el).draggable();
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.box-solid {
  border-radius: 5px;
  text-align: center;
  position: relative;
  box-shadow: 1px 2px 3px #aaa;
  min-width: 200px;
  margin-left: 10px;
  width: fit-content;
}

.custom-box-header {
  padding: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid gray;
}

.draggable {
  cursor: grab;
}

.box-body {
  padding: 10px 5px;
}

.table {
  text-align: center;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: #ddd;
}

.item-status-icon {
  min-width: 10px;
  min-height: 10px;
}
</style>
