<template>
  <section>
    <draggable class="list-group" v-model="fields">
      <div
        class="list-group-item item group-item"
        v-for="(item, ix) in fields"
        v-bind:key="ix"
      >
        <div :class="{disabled: !item.visible}">
          {{ $tc(item.title, 1) }}
        </div>
        <div class="item-buttons">
          <span
            class="btn btn-xs"
            v-on:click.prevent.stop="item.visible = !item.visible"
          >
            <i class="fa fa-eye" v-if="item.visible"></i>
            <i class="fa fa-eye-slash" v-else></i>
          </span>
        </div>
      </div>
    </draggable>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import draggable from "vuedraggable";
export default {
  name: "FieldListForm",
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data: () => ({
    fields: [
      // {
      //   name: "name",
      //   title: "alarm",
      //   visible: true,
      //   style: {
      //     "text-align": "left"
      //   }
      // },
      // {
      //   name: "status",
      //   title: "status",
      //   visible: true,
      //   style: {
      //     "text-align": "left"
      //   }
      // },
      // {
      //   name: "lastTransition",
      //   title: "last_event",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "ackStatus",
      //   title: "ack",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "current_value",
      //   title: "current_value",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "criteria_order",
      //   title: "criteria",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "nivel",
      //   title: "level",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "ack_enabled",
      //   title: "acknowledgement",
      //   visible: false,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "notification",
      //   title: "not",
      //   visible: false,
      //   style: {
      //     "text-align": "center"
      //   }
      // },
      // {
      //   name: "configure",
      //   title: "configure",
      //   visible: true,
      //   style: {
      //     "text-align": "center"
      //   }
      // }
    ]
  }),
  watch: {
    value: {
      handler(n) {
        if (n && !isEqual(n, this.fields)) {
          this.$set(this, "fields", JSON.parse(JSON.stringify(n)));
        }
      },
      deep: true,
      immediate: true
    },
    fields: {
      handler(n) {
        if (n && !isEqual(n, this.value)) {
          this.$emit("input", n);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.list-group-item {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  cursor: move;
}

.list-group-item:hover {
  opacity: 0.8;
}

.list-group-item > .disabled {
  color: #999;
}
</style>
