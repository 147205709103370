<template>
  <div class="form-group">
    <slot name="label"></slot>
    <v-select
      v-if="ready && visibleOptions.length"
      ref="selectElement"
      class="vselector"
      :value="query"
      :options="visibleOptions"
      :reduce="(option) => option.label.value"
      :getOptionLabel="label"
      :clearable="true"
      :placeholder="$tc('level', 2)"
      :clearSearchOnBlur="() => true"
      :disabled="$attrs.disabled ? true : false"
      @input="handleQueryInput($event)"
      @search="handleSearchInput($event)"
      @option:selected="handleOptionSelected($event)"
    >
      <template #selected-option="option">
        <div class="option" style="display: flex; align-items: baseline">
          <span
            v-if="option && option.icon && option.icon.show"
            :class="option.icon.class"
            :style="handleIconStyle(option.icon.style)"
          ></span>
          <span
            v-if="option && option.label && option.label.style"
            :style="handleLabelStyle(option.label.style)"
          >
            {{ label(option) }}
          </span>
        </div>
      </template>

      <template v-slot:option="option">
        <div class="option">
          <span
            v-if="option && option.icon && option.icon.show"
            :class="option.icon.class"
            :style="handleIconStyle(option.icon.style)"
          ></span>
          <span v-else :style="handleIconStyle(option.icon.style)"></span>
          <div :style="handleLabelStyle(option.label.style)" class="option-label">
            {{ label(option) }}
          </div>
          <div class="custom-item-toolbar">
            <i
              class="clicable fa fa-trash"
              :class="{disabled: isDefault(option.label.value)}"
              @click.stop.prevent="handleDelButton(option.label.value)"
            />
            <i
              class="clicable fa fa-edit"
              @click.stop.prevent="handleEditButton(option.label.value)"
            />
            <i
              class="clicable fa fa fa-eye"
              :class="{disabled: option.label.value == query}"
              @click.stop.prevent="handleToggleButton(option.label.value)"
            />
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #list-footer="{search, searching, loading}">
        <div class="footer-content" v-if="isNew">
          <div>
            <span>{{ $tc("color", 1) }} </span>
            <ColorPicker
              v-if="newEntry"
              v-model="iconColor"
              :show="iconColorPicker"
              buttonClass="btn btn-sm"
              class="color-picker"
              icon="background"
              style="white-space: normal; display: inline-block"
            />
          </div>
          <div>
            <span class="clicable" @click.stop.prevent="handleToggleIcon">
              <i class="fa fa-check-square-o" v-if="newEntry.icon.show"></i>
              <i class="fa fa-square-o" v-else></i>
              {{ $tc("icon", 1) }}
            </span>
            <span
              class="btn btn-sm btn-default"
              :class="{disabled: !newEntry.icon.show}"
              style="padding: 3px 6px"
              @click="iconPanelOpen = !iconPanelOpen"
            >
              <i :class="newEntry.icon.class"></i>
            </span>
          </div>
          <div class="pull-right">
            <span
              class="btn btn-sm"
              v-if="preEntry"
              @click="handleRestoreButton"
              :title="$t('undo')"
            >
              <i class="fa fa-undo"></i>
            </span>
            <span
              class="btn btn-sm btn-primary"
              @click="handleAddButton"
              :title="$tc('add', 1) + ': ' + query"
            >
              {{ query }}
              <i :class="preEntry ? 'fa fa-check' : 'fa fa-plus'"></i>
            </span>
          </div>
        </div>
        <div v-else-if="hiddenOptions.length" class="footer-content">
          <div class="pull-left" style="">
            {{ hiddenOptions.length }}
            {{ $tc("disabled", hiddenOptions.length > 1 ? 2 : 1) }}
          </div>
          <div class="pull-right">
            <span
              class="btn btn-xs btn-default"
              @click.stop.prevent="handleShowAll"
            >
              {{ $t("show_all") }}
            </span>
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="">
        <div></div>
      </template>
    </v-select>
    <IconLibrary
      style="max-height: 0; max-width: 0"
      :value="newEntry.icon.class"
      @input="handleIconSelection"
      :panelOpen="iconPanelOpen"
      @update:panelOpen="iconPanelOpen = $event"
      :buttonAttrs="null"
    >
      <template #button=""></template>
    </IconLibrary>
  </div>
</template>

<script>
import {isEqual, merge} from "lodash";
import ColorPicker from "@/components/editor/color-picker";
import IconLibrary from "@/components/editor/icon-library.vue";

const defaultAlarmLevelOption = () => ({
  icon: {
    show: false,
    class: "fa fa-exclamation-triangle",
    style: {color: "#666"}
  },
  label: {value: "", style: {color: "#333"}},
  custom: true,
  enabled: true
});

export {defaultAlarmLevelOption};
export default {
  name: "AlarmLevelForm",
  props: {
    value: {
      type: Object,
      default: () => ({
        query: "",
        options: []
      }),
      required: false
    }
  },
  components: {
    ColorPicker,
    IconLibrary
  },
  data() {
    return {
      query: "",
      newEntry: defaultAlarmLevelOption(),
      preEntry: null,
      customOptions: [],
      ready: true,
      iconPanelOpen: false,
      iconColorPicker: false
    };
  },
  computed: {
    defaultOptions() {
      return this.$root.config.alarm.levels || [];
    },
    hiddenOptions() {
      return this.customOptions.filter(({enabled}) => (enabled ? false : true));
    },
    visibleOptions() {
      // return [...this.defaultOptions, ...this.customOptions];
      return this.customOptions.filter(
        (option) => option.enabled || option.label.value == this.query
      );
    },
    isNew() {
      // return (
      //   this.query &&
      //   !this.customOptions.find((option) =>
      //     option.label.value.startsWith(this.query)
      //   )
      // );
      return (
        this.query &&
        !this.customOptions.some((option) => option.label.value === this.query)
      );
    },
    iconColor: {
      set(value) {
        if (value != this.newEntry.icon.style.color) {
          this.iconColorPicker = false;
        }
        this.newEntry.icon.style.color = value;
      },
      get() {
        return this?.newEntry?.icon?.style?.color || "#333";
      }
    }
  },
  watch: {
    value: {
      handler(n) {
        if (n) {
          if (!isEqual(n.query, this.query)) {
            this.query = n.query;
          }
          if (!isEqual(n.options, this.customOptions)) {
            this.$set(
              this,
              "customOptions",
              JSON.parse(JSON.stringify(n.options))
            );
          }
        }
      },
      immediate: true,
      deep: true
    },
    customOptions: {
      handler(n) {
        if (n) {
          if (isEqual(n, this?.value?.options)) return;
          this.$emit("input", {
            query: this.query,
            options: n
          });
        }
      },
      deep: true
    },
    query(n) {
      if (isEqual(n, this?.value?.query)) return;
      this.$emit("input", {
        query: n,
        options: this.customOptions
      });
    }
  },
  methods: {
    isDefault(value) {
      return this.defaultOptions.some(({label}) => label.value == value);
    },
    handleSearchInput(value) {
      if (this.skipInput) {
        this.skipInput = false;
        return;
      }
      if (this.iconPanelOpen) {
        if (this.$refs["selectElement"]) {
          this.$refs["selectElement"].open = true;
          this.$refs["selectElement"].search = this.newEntry.label.value;
        }
        return;
      }
      if (!value && this.preEntry) {
        this.undoEntry();
      } else {
        this.query = value;
      }
    },
    handleQueryInput(value) {
      this.query = value;
    },
    handleOptionSelected(option) {
      this.query = option.label.value;
      this.skipInput = true;
    },
    handleLabelStyle(style) {
      if (
        this.$root.isDarkTheme &&
        style.color == this.newEntry.label.style.color
      ) {
        return {
          ...style,
          color: "var(--skin-dark-light)"
        }
      }
      return style;
    },
    handleIconStyle(style) {
      if (
        this.$root.isDarkTheme && 
        style.color == this.newEntry.icon.color
      ) {
        return {
          ...style,
          color: "var(--skin-dark-light)"
        }
      }
      return style;
    },
    label(o) {
      return this.isDefault(o?.label?.value)
        ? this.$t(`alarm_level.${o?.label?.value}`)
        : o?.label?.value || o;
    },
    addEntry() {
      this.ready = false;
      var item = JSON.parse(JSON.stringify(this.newEntry));
      item.label.value = this.query;
      // this.customOptions.push(item);
      this.customOptions = [item, ...this.customOptions];
      this.newEntry.label.value = "";
      this.preEntry = null;
      this.$nextTick(() => {
        this.ready = true;
      });
    },
    undoEntry() {
      // var entry = null;
      // if (this.isDefault(this.newEntry.label.value)) {
      //   entry = this.defaultOptions.find(
      //     ({ label }) => label.value == this.newEntry.label.value
      //   );
      // } else {
      //   entry = this.preEntry;
      // }
      // this.$set(this, "newEntry", JSON.parse(JSON.stringify(entry)));
      this.query = this.preEntry.label.value;
      this.$set(this, "newEntry", JSON.parse(JSON.stringify(this.preEntry)));
      this.addEntry();
      // this.handleSearchInput(value);
    },
    handleAddButton() {
      this.addEntry();
    },
    handleIconSelection(value) {
      this.newEntry.icon.class = value;
      this.newEntry.label.value = this.query;
      if (this.$refs["selectElement"]) {
        this.$refs["selectElement"].open = true;
        this.$refs["selectElement"].search = this.newEntry.label.value;
      }
    },
    handleRestoreButton() {
      this.undoEntry();
    },
    handleEditButton(value) {
      var option = this.customOptions.find(
        (option) => option.label.value == value
      );
      if (option) {
        var entry = JSON.parse(
          JSON.stringify(merge(defaultAlarmLevelOption(), option))
        );
        var preEntry = entry;
        if (this.isDefault(value) || entry.original) {
          if (!entry.original) {
            entry.original = value;
          }
          preEntry = this.defaultOptions.find(
            ({label}) => label.value == entry.original
          );
        }
        this.$set(this, "preEntry", preEntry);
        this.$set(this, "newEntry", entry);
        this.customOptions = this.customOptions.filter(
          ({label}) => label.value != value
        );
        // this.query = value;
        if (this.$refs["selectElement"]) {
          this.$refs["selectElement"].open = true;
          this.$refs["selectElement"].search = this.newEntry.label.value;
        }
      }
    },
    handleDelButton(value) {
      if (this.isDefault(value)) return;
      let option = this.customOptions.find((i) => i.label.value == value);
      if (!option) return;
      this.customOptions = this.customOptions.filter(
        (option) => option.label.value != value
      );
      if (option.original) {
        var item = JSON.parse(
          JSON.stringify(
            this.defaultOptions.find((i) => i.label.value == option.original)
          )
        );
        this.customOptions = [item, ...this.customOptions];
      }
      if (this.query == value) {
        this.query = "";
      }
      this.preEntry = null;
    },
    handleToggleButton(value) {
      if (this.visibleOptions.length == 1 || value == this.query) return;
      var entry = this.customOptions.find(
        (option) => option.label.value == value
      );
      if (entry) {
        this.$set(entry, "enabled", !entry.enabled);
      }
    },
    handleShowAll() {
      this.customOptions.forEach((option) => {
        this.$set(option, "enabled", true);
      });
    },
    handleToggleIcon() {
      let icon = this.newEntry.icon || {};
      icon.show = !this.newEntry.icon.show;
      this.$set(this.newEntry, "icon", icon);
    }
  },
  mounted() {
    if (this?.$refs?.selectElement?.$el && this.query) {
      if (
        !(this.customOptions || []).some(({label}) => label.value == this.query)
      ) {
        this.addEntry();
      }
    }
  }
};
</script>

<style scoped>
.vselector {
  background-color: white;
}

.skin-dark .vselector {
  background-color: transparent;
}

datalist > option {
  color: red;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.footer-content {
  position: relative;
  margin-top: 0;
  border-top: 1px solid gray;
  padding: 6px 5px 2px 5px;
}

.footer-content > div {
  margin: 0 5px;
  display: inline-block;
}
.footer-content > div > label:first {
  margin-right: 2px;
  font-weight: 600;
}

.btn-confirm {
  color: #999;
  position: absolute;
  right: 5px;
  top: 10px;
  z-index: 1;
}

.option {
  position: relative;
}

.option > span {
  padding: 0 2px;
  min-width: 18px;
}

.option > div.option-label {
  padding: 0 2px;
  min-width: 18px;
  display: inline-block;
  max-width: 150px;
}

.custom-item-toolbar {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 5px;
  z-index: 3;
}

.custom-item-toolbar > i {
  margin: 0 5px;
  padding: 2px;
  z-index: 2;
}

i.disabled,
i.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

>>> {
  /* custom */
  --vs-dropdown-option-padding: 3px 10px 5px 10px;
  --vs-line-height: 1.2;
}
</style>

<style>
.vs__dropdown-toggle {
  min-height: 30px;
}
</style>
